import React, { useEffect, useState } from "react";
import PageHeading from "../PageHeading";
import {
  ref as storageRef,
  getDownloadURL,
  uploadString,
} from "firebase/storage";
import { storage, database } from "../../../firebaseAdmin";
import { v4 as uuidv4 } from "uuid";
import { ref as rldbRef, set, get } from "firebase/database";
import { useDispatch } from "react-redux";
import { showOrHideLoader } from "../../../redux/reducers/loaderSlicer";
import { triggerSnackbar } from "../../../redux/reducers/snackbarSlicer";

function AddPoster() {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [allPosters, setAllPosters] = useState([]);

  useEffect(() => {
    get(rldbRef(database, `posters`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let data = snapshot.val();
          setAllPosters(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setImageName(file.name);
        setImageType(file.type);
      };
      reader.readAsDataURL(file);
    }
  };

  const writeData = (url, token) => {
    set(rldbRef(database, "posters"), [
      ...allPosters,
      {
        fileName: imageName,
        fileUrl: url,
        token: token,
      },
    ])
      .then(() => {
        console.log("Data written successfully!");
      })
      .catch((error) => {
        console.error("Error writing data: ", error);
      });
  };

  const handleUpload = () => {
    dispatch(showOrHideLoader());
    const token = uuidv4();
    if (!image) return;

    // Create a storage reference
    const posterStorageRef = storageRef(storage, `posters/${token}`);
    const metadata = {
      contentType: imageType,
    };

    uploadString(posterStorageRef, image, "data_url", metadata)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then(async (url) => {
          // Url
          writeData(url, token);
          dispatch(
            triggerSnackbar({
              show: true,
              message: "Poster Successfully Uploaded",
              type: "success",
            })
          );
          // dispatch(showOrHideLoader());
        });
      })
      .catch((error) => {
        dispatch(
          triggerSnackbar({
            show: true,
            message: "Something went wrong",
            type: "error",
          })
        );
        // dispatch(showOrHideLoader());
      })
      .finally(() => {
        dispatch(showOrHideLoader());
      });
  };

  return (
    <>
      <PageHeading pageName={"Add Poster"} />
      <div className="w-full flex flex-col justify-center items-center">
        {image ? (
          <img
            src={image}
            alt="Selected Preview"
            className="w-full my-4"
            style={{ width: "50%", aspectRatio: 16 / 9, objectFit: "contain" }}
          />
        ) : (
          <div
            className="bg-slate-400 my-4 rounded-md flex justify-center items-center"
            style={{ width: "50%", aspectRatio: 16 / 9 }}
          >
            <h1>No poster seleted</h1>
          </div>
        )}
        <form className="max-w-lg mx-auto">
          <label
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            htmlFor="user_avatar"
          >
            Upload Poster
          </label>
          <input
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            aria-describedby="user_avatar_help"
            id="user_avatar"
            type="file"
            onChange={handleImageChange}
          />
        </form>
        <button
          onClick={handleUpload}
          type="button"
          className="my-4 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        >
          Upload Poster
        </button>
      </div>
    </>
  );
}

export default AddPoster;
