import React, { useEffect, useState } from "react";
import { Sunrise, Sunset, Sun, Moon, SunMoon } from "lucide-react";
import FajrIcon from "../../../assets/icons/icon-fajr.svg";
import { useDispatch } from "react-redux";
import { showOrHideLoader } from "../../../redux/reducers/loaderSlicer";
import { database } from "../../../firebaseAdmin";
import { ref as rldbRef, get } from "firebase/database";

function Table({ year, month, date }) {
  const [data, setData] = useState({
    asr_begins: "16:33:00",
    asr_jamah: "17:00:00",
    d_date: "10/4/2024",
    fajr_begins: "5:06:00",
    fajr_jamah: "5:30:00",
    hijri_date: "0",
    is_ramadan: "0",
    isha_begins: "19:27:00",
    isha_jamah: "19:45:00",
    maghrib_begins: "18:12:00",
    maghrib_jamah: "18:12:00",
    sunrise: "6:20:00",
    zuhr_begins: "12:16:00",
    zuhr_jamah: "12:45:00",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showOrHideLoader());
    get(rldbRef(database, `prayerTime/${year}/${month}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let data = snapshot.val();
          data = data[date - 1];
          //   console.log("Data :", data);
          setData(data);
        } else {
          console.warn("No data available");
        }
        dispatch(showOrHideLoader());
      })
      .catch((error) => {
        console.error(error);
        dispatch(showOrHideLoader());
      });
    // eslint-disable-next-line
  }, []);
  const createTimeString = (time) => {
    time = time.split(":");
    let hour = parseInt(time[0]);
    let minutes = parseInt(time[1]);
    let format = "AM";
    if (hour >= 12) {
      hour = hour % 12;
      format = "PM";
    }
    return `${hour >= 10 ? "" : "0"}${hour}:${minutes >= 10 ? "" : "0"}${minutes} ${format}`;
  };
  return (
    <div className="relative overflow-x-auto ">
      <table className="w-full h-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded-lg">
        <thead className="text-xs font-bold text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 bg-green-400">
          <tr className="text-center">
            <th scope="col" className="px-6 py-3"></th>
            <th scope="col" className="px-6 py-3"></th>
            <th scope="col" className="px-6 py-3">
              Start
            </th>
            <th scope="col" className="px-6 py-3">
              Jama'at
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
            <td className="px-2 py-4 text-center">
              {/* <img src={FajrIcon} width={18} height={18} className="mr-2" /> */}
              Fajr
            </td>
            <td className="px-2 py-4 flex justify-center">
              <img
                src={FajrIcon}
                alt="fajr-icon"
                width={18}
                height={18}
                className="mr-2"
              />
            </td>
            <td className="px-2 py-4 text-center">
              {createTimeString(data["fajr_begins"])}
            </td>
            <td className="px-2 py-4 text-center">
              {createTimeString(data["fajr_jamah"])}
            </td>
          </tr>
          <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
            <td className="px-2 py-4 text-center">Sunrise</td>
            <td className="px-2 py-4 flex justify-center">
              <Sunrise size={18} className="mr-2" />
            </td>
            <td className="px-2 py-4 text-center" colSpan={2}>
              {createTimeString(data["sunrise"])}
            </td>
          </tr>
          <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
            <td className="px-2 py-4 text-center">Dhuhr</td>
            <td className="px-2 py-4 flex justify-center">
              <Sun size={18} className="mr-2" />
            </td>
            <td className="px-2 py-4 text-center">
              {createTimeString(data["zuhr_begins"])}
            </td>
            <td className="px-2 py-4 text-center">
              {createTimeString(data["zuhr_jamah"])}
            </td>
          </tr>
          <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
            <td className="px-2 py-4 text-center">Asar</td>
            <td className="px-2 py-4 flex justify-center">
              <SunMoon size={18} className="mr-2" />
            </td>
            <td className="px-2 py-4 text-center">
              {createTimeString(data["asr_begins"])}
            </td>
            <td className="px-2 py-4 text-center">
              {createTimeString(data["asr_jamah"])}
            </td>
          </tr>
          <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
            <td className="px-2 py-4 text-center">Maghrib</td>
            <td className="px-2 py-4 flex justify-center">
              <Sunset size={18} className="mr-2" />
            </td>
            <td className="px-2 py-4 text-center" colSpan={2}>
              {createTimeString(data["maghrib_begins"])}
            </td>
          </tr>
          <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 rounded-b-lg">
            <td className="px-2 py-4 text-center">Isha</td>
            <td className="px-2 py-4 flex justify-center">
              <Moon size={18} className="mr-2" />
            </td>
            <td className="px-2 py-4 text-center">
              {createTimeString(data["isha_begins"])}
            </td>
            <td className="px-2 py-4 text-center">
              {createTimeString(data["isha_jamah"])}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Table;
