// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCIN8_HexanKZNaudWuQv_bkuEqUFrVg6U",
  authDomain: "balgreenmosque-70c71.firebaseapp.com",
  projectId: "balgreenmosque-70c71",
  storageBucket: "balgreenmosque-70c71.appspot.com",
  messagingSenderId: "44017375618",
  appId: "1:44017375618:web:23d522640cd7e2cd07179b",
  measurementId: "G-8LDV513CFE",
  databaseURL:
    "https://balgreenmosque-70c71-default-rtdb.europe-west1.firebasedatabase.app",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const database = getDatabase(app);
export const auth = getAuth(app);
