import React from "react";
import Logo from "../../assets/logo/mini_logo.png";
import MenuOpen from "../../assets/icons/menu-open.png";
import MenuOpenWhite from "../../assets/icons/menu-open-white.png";
import { showOrHideLeftNavbar } from "../../redux/reducers/leftNavbarSlicer";
import { useDispatch, useSelector } from "react-redux";
import { Power } from "lucide-react";
import { signOut } from "firebase/auth";
import { auth } from "../../firebaseAdmin";
import { useNavigate } from "react-router-dom";
function TopNavbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const leftNavbar = useSelector((state) => state.leftNavbar.value);

  const handleMenuOpen = () => {
    dispatch(showOrHideLeftNavbar());
  };

  const handleSignOut = () => {
    // console.log("handleSignOut");

    signOut(auth)
      .then(() => {
        // Sign-out successful.
        // console.log("Signed Out");
      })
      .catch((error) => {
        // An error happened.
        console.log("Error : ", error);
      })
      .finally(() => {
        navigate("/auth");
      });
  };
  return (
    <>
      <nav className="bg-white border-gray-200 w-full">
        <div className="max-w-screen flex flex-wrap items-center justify-between sm:mx-10 p-4">
          {/* {!leftNavbar ? ( */}
          <img
            src={leftNavbar ? MenuOpenWhite : MenuOpen}
            alt="menu-open"
            width={30}
            height={30}
            style={{
              cursor: `${leftNavbar ? "default" : "pointer"}`,
            }}
            onClick={handleMenuOpen}
          />
          {/* ) : null} */}

          <a
            href="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src={Logo} className="h-8" alt="Flowbite Logo" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap">
              {process.env.REACT_APP_NAME.toUpperCase()}
            </span>
          </a>
          <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <button
              onClick={handleSignOut}
              type="button"
              className="flex text-sm rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 text-red-600"
              id="user-menu-button"
              aria-expanded="false"
              // data-dropdown-toggle="user-dropdown"
              data-dropdown-placement="bottom"
            >
              {/* <span className="sr-only">Open user menu</span>
              <img
                className="w-8 h-8 rounded-full"
                src="/docs/images/people/profile-picture-3.jpg"
                alt="temp"
              /> */}
              <Power />
            </button>
            {/* <!-- Dropdown menu --> */}
            {/* <div
              className="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
              id="user-dropdown"
            >
              <div className="px-4 py-3">
                <span className="block text-sm text-gray-900 dark:text-white">
                  Bonnie Green
                </span>
                <span className="block text-sm  text-gray-500 truncate dark:text-gray-400">
                  name@flowbite.com
                </span>
              </div>
              <ul className="py-2" aria-labelledby="user-menu-button">
                <li>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  >
                    Dashboard
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  >
                    Settings
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  >
                    Earnings
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  >
                    Sign out
                  </a>
                </li>
              </ul>
            </div> */}
            {/* <button
              data-collapse-toggle="navbar-user"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-user"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button> */}
          </div>
        </div>
      </nav>
    </>
  );
}

export default TopNavbar;
