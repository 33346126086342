import React, { useEffect, useState } from "react";
import { database } from "../../../firebaseAdmin";
import { ref as rldbRef, get } from "firebase/database";
import TimeTable from "./TimeTable";
import { useDispatch } from "react-redux";
import { showOrHideLoader } from "../../../redux/reducers/loaderSlicer";
import { monthNameAbbrToFull } from "../../../constants/MonthName";

function PrayerMonthTabs({ currentYear }) {
  const dispatch = useDispatch();
  const tabItems = Object.keys(monthNameAbbrToFull);
  const [selectedId, setSelectedId] = useState("jan-tab");
  const [timeTable, setTimeTable] = useState(null);
  const handleClick = (event) => {
    setSelectedId(event.target.id);
  };
  useEffect(() => {
    dispatch(showOrHideLoader());
    get(rldbRef(database, `prayerTime/${currentYear}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let data = snapshot.val();
          setTimeTable(data);
        } else {
          console.warn("No data available");
        }
        dispatch(showOrHideLoader());
      })
      .catch((error) => {
        console.error(error);
        dispatch(showOrHideLoader());
      });
    // eslint-disable-next-line
  }, []);

  const TabItem = ({ item }) => {
    let selected = selectedId.split("-")[0] === item.toLowerCase();
    return (
      <li className={selected ? `text-green-700` : ""} role="presentation">
        <button
          className={`inline-block p-4 border-b-2 ${selected ? "border-green-700" : ""} rounded-t-lg`}
          id={`${item.toLowerCase()}-tab`}
          onClick={handleClick}
        >
          {monthNameAbbrToFull[item]}
        </button>
      </li>
    );
  };
  return (
    <>
      {timeTable ? (
        <>
          <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
            <ul
              className="flex flex-wrap -mb-px text-sm font-medium text-center justify-evenly"
              id="default-tab"
              data-tabs-toggle="#default-tab-content"
              role="tablist"
            >
              {tabItems.map((elem) => (
                <TabItem item={elem} />
              ))}
            </ul>
          </div>
          <div id="default-tab-content">
            {(() => {
              switch (selectedId) {
                case "jan-tab":
                  return (
                    <TimeTable
                      dataArray={timeTable["Jan"]}
                      monthName={"Jan"}
                      currentYear={currentYear}
                    />
                  );
                case "feb-tab":
                  return (
                    <TimeTable
                      dataArray={timeTable["Feb"]}
                      monthName={"Feb"}
                      currentYear={currentYear}
                    />
                  );
                case "mar-tab":
                  return (
                    <TimeTable
                      dataArray={timeTable["Mar"]}
                      monthName={"Mar"}
                      currentYear={currentYear}
                    />
                  );
                case "apr-tab":
                  return (
                    <TimeTable
                      dataArray={timeTable["Apr"]}
                      monthName={"Apr"}
                      currentYear={currentYear}
                    />
                  );
                case "may-tab":
                  return (
                    <TimeTable
                      dataArray={timeTable["May"]}
                      monthName={"May"}
                      currentYear={currentYear}
                    />
                  );
                case "jun-tab":
                  return (
                    <TimeTable
                      dataArray={timeTable["Jun"]}
                      monthName={"Jun"}
                      currentYear={currentYear}
                    />
                  );
                case "jul-tab":
                  return (
                    <TimeTable
                      dataArray={timeTable["Jul"]}
                      monthName={"Jul"}
                      currentYear={currentYear}
                    />
                  );
                case "aug-tab":
                  return (
                    <TimeTable
                      dataArray={timeTable["Aug"]}
                      monthName={"Aug"}
                      currentYear={currentYear}
                    />
                  );
                case "sep-tab":
                  return (
                    <TimeTable
                      dataArray={timeTable["Sep"]}
                      monthName={"Sep"}
                      currentYear={currentYear}
                    />
                  );
                case "oct-tab":
                  return (
                    <TimeTable
                      dataArray={timeTable["Oct"]}
                      monthName={"Oct"}
                      currentYear={currentYear}
                    />
                  );
                case "nov-tab":
                  return (
                    <TimeTable
                      dataArray={timeTable["Nov"]}
                      monthName={"Nov"}
                      currentYear={currentYear}
                    />
                  );
                case "dec-tab":
                  return (
                    <TimeTable
                      dataArray={timeTable["Dec"]}
                      monthName={"Dec"}
                      currentYear={currentYear}
                    />
                  );
              }
            })()}
          </div>
        </>
      ) : null}
    </>
  );
}

export default PrayerMonthTabs;
