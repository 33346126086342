import Loader from "./components/Loader/Loader";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Showcase/Home";
import Admin from "./components/Admin";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { triggerSnackbar } from "./redux/reducers/snackbarSlicer";
import { Helmet } from "react-helmet-async";
import { forwardRef } from "react";
import MuiAlert from "@mui/material/Alert";
import SignIn from "./components/Admin/SignIn";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const loader = useSelector((state) => state.loader.value);
  const snackbar = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(triggerSnackbar({ show: false }));
  };
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME}</title>
      </Helmet>
      {loader ? <Loader /> : null}
      <div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/auth" element={<SignIn />} />
          <Route exact path="/admin" element={<Admin />} />
        </Routes>
        <Snackbar
          open={snackbar.show}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={snackbar.type}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default App;
