import React from "react";
import Navbar from "./Navbar";
import Services from "./Services";
import AboutUs from "./AboutUs";
import Location from "./Location";
import Support from "./Support";
import GetInTouch from "./GetInTouch";
import CarouselAndTable from "./CarouselAndTable";

function Home() {
  return (
    <>
      <Navbar />
      <CarouselAndTable />
      <Support />
      <AboutUs />
      <Location />
      <Services />
      <GetInTouch />
    </>
  );
}

export default Home;
