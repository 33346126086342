import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { showOrHideLoader } from "../../redux/reducers/loaderSlicer";
import { ref as rldbRef, onValue } from "firebase/database";
import { database } from "../../firebaseAdmin";
import Slider from "react-slick";

function Carousel() {
  var settings = {
    dots: false, // Enable navigation dots
    infinite: true, // Loop the slides
    speed: 1500, // Transition speed
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Automatically transition slides
    autoplaySpeed: 3000, // 3-second interval
    arrows: false, // Enable navigation arrows
    pauseOnHover: true, // Pause autoplay on hover
  };
  const dispatch = useDispatch();
  let [posters, setPosters] = useState([]);
  useEffect(() => {
    dispatch(showOrHideLoader());
    // console.log("posters useEffect");
    const starCountRef = rldbRef(database, "posters");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      //   console.log("Posters Data : ", data);
      dispatch(showOrHideLoader());
      setPosters(data);
    });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="relative" key={"carousel"}>
        <Slider {...settings} className="overflow-hidden">
          {/* Slide 1 */}
          {posters.length &&
            posters.map((poster) => {
              return (
                // <div className="relative">
                <img
                  key={poster.token}
                  src={poster.fileUrl}
                  alt={poster.fileName}
                  className="w-full object-contain h-full"
                  // style={{ aspectRatio: 16 / 8 }}
                />
                // </div>
              );
            })}
        </Slider>
      </div>
    </>
  );
}

export default Carousel;
