import React from "react";
import LogoS1 from "../../assets/icons/s1.png";
import LogoS2 from "../../assets/icons/s2.png";
import LogoS3 from "../../assets/icons/s3.png";
import LogoS4 from "../../assets/icons/s4.png";

// import EcoFriendly from "../assets/icons/eco-friendly.png";
// import WaterResistant from "../assets/icons/water-resistant.png";
// import AffordablePricing from "../assets/icons/affordable-pricing.png";
// import colorPalette from './constants/colors';

const Services = () => {
  const features = [
    {
      title: "Quran Studies",
      description:
        "I challenge anyone to Islam, its spirit, and not to love it. It is a religion of brotherhood and peace",
      image: LogoS1,
    },
    {
      title: "Mosque Renovation",
      description:
        "Once Prayers become anyone to understand Islam, its spirit, A and not to love it. beautiful",
      image: LogoS2,
    },
    {
      title: "Funeral Service",
      description:
        "I challenge anyone to Islam, its spirit, and not to love it. It is a religion of brotherhood and",
      image: LogoS3,
    },
    {
      title: "Hadith School",
      description:
        "We Have A anyone to understand Islam, its spirit, and not to love it. beautiful religion of",
      image: LogoS4,
    },
  ];

  return (
    // <section id="features" className="py-16 bg-orange-200">
    <section id="service" className="py-16 bg-[#F9F9F9]">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-12 uppercase">
          Our <span className="text-[#DB9E2F]">Services</span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-16">
          {features.map((feature, index) => (
            <div
              key={index}
              //   className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow text-center"
            >
              <div className="flex justify-center items-center mb-4">
                <img
                  src={feature.image}
                  alt={feature.title}
                  className="w-16 h-16 object-contain"
                />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-center text-[#DB9E2F] uppercase">
                {feature.title}
              </h3>
              <p className="text-center">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
