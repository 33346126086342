import React from "react";
import backgroundImage from "../../assets/images/locationBackgroundImage.jpg";
import qr from "../../assets/images/qr.png";
import Map from "./Map";
function Location() {
  return (
    <div
      id="contact"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 items-center justify-around px-4 md:px-16 py-16 w-full h-full bg-[#C17E03] bg-opacity-40">
        <div className="bg-[#FAF0E0] rounded-md p-4">
          <div className="border-gray-400 border-2 border-dashed rounded-md p-4">
            <h1 className="mt-4 text-center text-[#DB9E2F] font-bold text-md">
              For Our WhatsApp Channel
            </h1>
            <div className="border-gray-400 border-2 border-dashed rounded-md mx-4 mb-4 mt-2 p-4">
              <img src={qr} />
            </div>
          </div>
        </div>

        <div className="bg-[#FAF0E0] rounded-md p-4">
          <div className="border-gray-400 border-2 border-dashed rounded-md p-4">
            <h1 className="mt-4 text-center text-[#DB9E2F] font-bold text-md">
              Our Location
            </h1>
            <div className="mx-4 mb-4 mt-2">
              {/* <img src={qr} /> */}
              <Map />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Location;
