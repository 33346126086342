import React, { useState } from "react";
import PageHeading from "../PageHeading";
import { database } from "../../../firebaseAdmin";
import { ref as rldbRef, set } from "firebase/database";
import Papa from "papaparse";
import { monthNameToNum } from "../../../constants/MonthName";

function AddTimeTable() {
  const [year, setYear] = useState(null);
  const [timeTable, setTimeTable] = useState(null);
  const monthNames = Object.keys(monthNameToNum);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          // console.log({ results });
          setTimeTable(results.data);
          let year = results.data[0]["d_date"];
          year = year.split("/").pop();
          setYear(year);
        },
      });
    }
  };

  const writeData = (year, data) => {
    // console.log("writeData");
    set(rldbRef(database, `prayerTime/${year}`), data)
      .then(() => {
        console.log("Data written successfully!");
      })
      .catch((error) => {
        console.error("Error writing data: ", error);
      });
  };

  const processJSON = (data) => {
    let obj = {};

    data.forEach((element) => {
      let month = element["d_date"];
      if (month.length) {
        month = month.split("/")[0];
        month = monthNames[month - 1];
        // console.log("month: ", month);
        if (obj[month] !== undefined) {
          obj[month] = [...obj[month], element];
        } else {
          obj[month] = [element];
        }
      }
    });
    return obj;
  };

  const handleUpload = () => {
    let processedData = processJSON(timeTable);
    writeData(year, processedData);
  };

  return (
    <>
      <PageHeading pageName={"Add Time Table"} />
      <div className="w-full h-full flex flex-col justify-center items-center">
        <form className="max-w-lg mx-auto">
          <label
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            htmlFor="user_avatar"
          >
            Select Time Table CSV
          </label>
          <input
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            aria-describedby="user_avatar_help"
            id="user_avatar"
            type="file"
            accept="csv"
            onChange={handleFileChange}
          />
        </form>
        <button
          onClick={handleUpload}
          type="button"
          className="my-4 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        >
          Upload
        </button>
      </div>
    </>
  );
}

export default AddTimeTable;
