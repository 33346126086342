import React from "react";
import TopNavbar from "../navbars/TopNavbar";
import { useSelector } from "react-redux";
import Footer from "../Footer";
import AddPoster from "./Posters/AddPoster";
import AllPosters from "./Posters/AllPosters";
import PrayerTimeMain from "./PrayerTime/PrayerTimeMain";
import AddTimeTable from "./PrayerTime/AddTimeTable";

function MainComponent() {
  const selected = useSelector((state) => state.sidebarActive.selector);
  const selectedSubItem = useSelector(
    (state) => state.sidebarSubItemActive.selector
  );
  return (
    <>
      <div className="flex flex-col w-full h-screen">
        <TopNavbar />
        <div className="p-4 sm:p-8 overflow-y-auto h-[inherit]">
          {(() => {
            switch (selected) {
              case "prayer-time":
                switch (selectedSubItem) {
                  case "all-prayer-time":
                    return <PrayerTimeMain />;
                  case "add-prayer-time":
                    return <AddTimeTable />;
                  default:
                    break;
                }
                break;
              case "poster":
                switch (selectedSubItem) {
                  case "all-poster":
                    return <AllPosters />;
                  case "add-poster":
                    return <AddPoster />;
                  default:
                    break;
                }
                break;
              default:
                break;
            }
          })()}
        </div>
        <Footer />
      </div>
    </>
  );
}

export default MainComponent;
