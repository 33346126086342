import React, { useState } from "react";
import { useContext, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeSidebarActiveCell } from "../../redux/reducers/sidebarActiveSlicer";
import {
  ChevronDown,
  ChevronUp,
  ImagePlus,
  Images,
  BookImage,
  CalendarClock,
  Grid2x2PlusIcon,
  Clock3,
} from "lucide-react";
import MenuClose from "../../assets/icons/menu-close.png";
import { showOrHideLeftNavbar } from "../../redux/reducers/leftNavbarSlicer";
import { changeSidebarSubItemActiveCell } from "../../redux/reducers/sidebarSubItemActiveSlicer";

const SidebarContext = createContext();

function Sidebar({ children }) {
  const dispatch = useDispatch();
  const leftNavbar = useSelector((state) => state.leftNavbar.value);
  const handleMenuClose = () => {
    dispatch(showOrHideLeftNavbar());
  };

  return (
    <aside>
      <nav
        className={`z-10 absolute lg:relative h-full md:h-screen sm:flex flex-row sm:flex-col bg-[white] border-r shadow-sm transition-all duration-300 ${
          leftNavbar ? "w-60" : "w-[0px]"
        }`}
      >
        <div
          className={`${!leftNavbar ? "hidden" : "flex"} px-4 py-4 justify-between items-center border-b`}
        >
          {leftNavbar ? (
            <h3 className="text-black text-2xl font-bold">Menu</h3>
          ) : null}
          <img
            src={MenuClose}
            alt="menu-close"
            width={30}
            height={30}
            style={{
              cursor: "pointer",
            }}
            onClick={handleMenuClose}
          />
        </div>

        <SidebarContext.Provider value={{ leftNavbar }}>
          <ul className={`${leftNavbar ? "flex-1" : "hidden"} p-4`}>
            {children}
          </ul>
        </SidebarContext.Provider>
      </nav>
    </aside>
  );
}

// function SidebarItem({ icon, text, selector }) {
//   const { leftNavbar } = useContext(SidebarContext);
//   const selected = useSelector((state) => state.sidebarActive.selector);
//   const dispatch = useDispatch();
//   return (
//     <li
//       onClick={() => {
//         dispatch(changeSidebarActiveCell(selector));
//         dispatch(changeSidebarSubItemActiveCell(null));
//       }}
//       className={`
//         relative flex items-center py-2 px-3 my-1
//         font-medium rounded-md cursor-pointer
//         transition-colors group
//         ${selector === selected ? "bg-emerald-100 text-[green]" : "hover:text-[green]"}
//     `}
//     >
//       {icon}
//       <span className={`overflow-hidden ${leftNavbar ? "ml-3" : "w-[0px]"}`}>
//         {text}
//       </span>
//     </li>
//   );
// }

function SidebarItemWithDropdown({
  icon,
  text,
  selector,
  ariaControl,
  dataCollapseToggle,
  defaultActiveSubItem = null,
}) {
  const { leftNavbar } = useContext(SidebarContext);
  const selected = useSelector((state) => state.sidebarActive.selector);
  const [isExpanded, setIsExpanded] = useState(selector === selected);
  const dispatch = useDispatch();
  return (
    <button
      aria-controls={ariaControl}
      data-collapse-toggle={dataCollapseToggle}
      onClick={() => {
        setIsExpanded(!isExpanded);
        dispatch(changeSidebarActiveCell(selector));
        dispatch(changeSidebarSubItemActiveCell(defaultActiveSubItem));
      }}
      className={`
        w-full relative flex justify-between items-center py-2 px-3 my-1
        font-medium rounded-md cursor-pointer
        transition-all duration-300 ease-in-out group
        ${selector === selected ? "bg-emerald-100 text-[green]" : "hover:text-[green]"}
    `}
    >
      <div className="flex">
        {icon}
        <span className={`overflow-hidden ${leftNavbar ? "ml-3" : "w-[0px]"}`}>
          {text}
        </span>
      </div>
      {selector === selected && isExpanded ? <ChevronUp /> : <ChevronDown />}
    </button>
  );
}

function SubItem({ icon, text, selector }) {
  const subItemSelected = useSelector(
    (state) => state.sidebarSubItemActive.selector
  );
  const dispatch = useDispatch();
  return (
    <li
      onClick={() => dispatch(changeSidebarSubItemActiveCell(selector))}
      className={`
        relative flex items-center py-2 px-3 my-1
        font-medium rounded-md cursor-pointer
        transition-colors group
        ${selector === subItemSelected ? "text-[green]" : "hover:text-[green]"}
    `}
    >
      {icon}
      <span className={`overflow-hidden ml-3`}>{text}</span>
    </li>
  );
}

function LeftNavbar() {
  const selected = useSelector((state) => state.sidebarActive.selector);
  return (
    <>
      <Sidebar>
        <SidebarItemWithDropdown
          text={"Prayer Time"}
          icon={<Clock3 />}
          selector={"prayer-time"}
          defaultActiveSubItem={"all-prayer-time"}
          ariaControls="prayer-dropdown"
          dataCollapseToggle="prayer-dropdown"
        />
        <ul
          id="prayer-dropdown"
          className={`${selected === "prayer-time" ? "ml-8" : "hidden"}`}
        >
          <SubItem
            text={"All Prayer Time"}
            icon={<CalendarClock />}
            selector={"all-prayer-time"}
          />
          <SubItem
            text={"Add Prayer Time"}
            icon={<Grid2x2PlusIcon />}
            selector={"add-prayer-time"}
          />
        </ul>
        <SidebarItemWithDropdown
          text={"Posters"}
          icon={<BookImage />}
          selector={"poster"}
          defaultActiveSubItem={"all-poster"}
          ariaControls="poster-dropdown"
          dataCollapseToggle="poster-dropdown"
        />
        <ul
          id="poster-dropdown"
          className={`${selected === "poster" ? "ml-8" : "hidden"}`}
        >
          <SubItem
            text={"All Poster"}
            icon={<Images />}
            selector={"all-poster"}
          />
          <SubItem
            text={"Add Poster"}
            icon={<ImagePlus />}
            selector={"add-poster"}
          />
        </ul>
      </Sidebar>
    </>
  );
}

export default LeftNavbar;
