import React from "react";

function PageHeading({ pageName }) {
  return (
    <div
      key={"page-heading"}
      className="text-2xl font-semibold bg-emerald-100 py-2 flex justify-center rounded-md"
    >
      {pageName}
    </div>
  );
}

export default PageHeading;
