import React from "react";

function Map() {
  return (
    <div className="">
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d146.55648723727495!2d-3.2460558935683594!3d55.934381816175524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4887c6f9b12cf119%3A0xa5c6a9062f7572b4!2sMescid-i%20Ey%C3%BCp%20Sultan%20And%20Turkish%20Community%20Centre!5e0!3m2!1sen!2sin!4v1727867268239!5m2!1sen!2sin"
        style={{ border: 0, width: "100%", aspectRatio: 1 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Map;
