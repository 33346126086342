import React, { useEffect, useState } from "react";
import {
  monthNameAbbrToFull,
  monthNameToNum,
} from "../../../constants/MonthName";
import { database } from "../../../firebaseAdmin";
import { ref as rldbRef, set } from "firebase/database";

function TableCell({ time, id, index, handleDataUpdate }) {
  const handleChange = (e) => {
    let val = e.target.value;
    let id = e.target.id;
    setTimeValue(val);
    handleDataUpdate(val, id);
  };
  const formatTime = (timeStr) => {
    timeStr = timeStr.split(":");
    let hour = parseInt(timeStr[0]);
    let minutes = timeStr[1];
    hour = hour.toString();
    if (hour.length < 2) {
      hour = `0${hour}`;
    }
    let result = `${hour}:${minutes}`;

    return result;
  };
  const [timeValue, setTimeValue] = useState("");
  useEffect(() => {
    setTimeValue(formatTime(time));
  }, [time]);
  return (
    <td className="p-2 text-center">
      <div class="relative">
        <div class="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
          <svg
            class="w-4 h-4 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <input
          type="time"
          id={`${id}_${index}`}
          class="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          value={timeValue}
          // defaultValue={formatTime(time)}
          onChange={handleChange}
        />
      </div>
    </td>
  );
}

function TableRow({ row, index, handleDataUpdate }) {
  const months = Object.keys(monthNameToNum);
  const formatDate = (date) => {
    date = date.split("/");
    date = `${monthNameAbbrToFull[months[parseInt(date[0]) - 1]]} ${date[1]}, ${date[2]}`;
    return date;
  };

  return (
    <tr
      key={"data-row"}
      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
    >
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {formatDate(row["d_date"])}
      </th>

      <TableCell
        time={row["fajr_begins"]}
        id={"fajr_begins"}
        index={index}
        handleDataUpdate={handleDataUpdate}
      />
      <TableCell
        time={row["fajr_jamah"]}
        id={"fajr_jamah"}
        index={index}
        handleDataUpdate={handleDataUpdate}
      />
      <TableCell
        time={row["sunrise"]}
        id={"sunrise"}
        index={index}
        handleDataUpdate={handleDataUpdate}
      />
      <TableCell
        time={row["zuhr_begins"]}
        id={"zuhr_begins"}
        index={index}
        handleDataUpdate={handleDataUpdate}
      />
      <TableCell
        time={row["zuhr_jamah"]}
        id={"zuhr_jamah"}
        index={index}
        handleDataUpdate={handleDataUpdate}
      />
      <TableCell
        time={row["asr_begins"]}
        id={"asr_begins"}
        index={index}
        handleDataUpdate={handleDataUpdate}
      />
      <TableCell
        time={row["asr_jamah"]}
        id={"asr_jamah"}
        index={index}
        handleDataUpdate={handleDataUpdate}
      />
      <TableCell
        time={row["maghrib_begins"]}
        id={"maghrib_begins"}
        index={index}
        handleDataUpdate={handleDataUpdate}
      />
      <TableCell
        time={row["maghrib_jamah"]}
        id={"maghrib_jamah"}
        index={index}
        handleDataUpdate={handleDataUpdate}
      />
      <TableCell
        time={row["isha_begins"]}
        id={"isha_begins"}
        index={index}
        handleDataUpdate={handleDataUpdate}
      />
      <TableCell
        time={row["isha_jamah"]}
        id={"isha_jamah"}
        index={index}
        handleDataUpdate={handleDataUpdate}
      />
    </tr>
  );
}

function TimeTable({ dataArray, monthName, currentYear }) {
  let [temporary, setTemporary] = useState(dataArray);
  const handleDataUpdate = (val, id) => {
    let obj = {};
    obj[monthName] = temporary;
    let index = id.split("_").pop();
    let key = id.replace(`_${index}`, "");
    index = parseInt(index);
    obj[monthName][index][key] = val;
    setTemporary(obj);
  };
  const writeData = (year, month) => {
    let data = temporary[month];
    set(rldbRef(database, `prayerTime/${year}/${month}`), data)
      .then(() => {
        console.log("Data written successfully!");
      })
      .catch((error) => {
        console.error("Error writing data: ", error);
      });
  };
  const handleButton = () => {
    writeData(currentYear, monthName);
  };
  return (
    <>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg rounded-lg p-4">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr key={"heading"}>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              {/* <th scope="col" className="px-6 py-3">
                Day
              </th> */}
              <th scope="col" className="text-center px-2 py-3">
                Fajr
              </th>
              <th scope="col" className="text-center px-2 py-3">
                Fajr Jama'at
              </th>
              <th scope="col" className="text-center px-2 py-3">
                Sunrise
              </th>
              <th scope="col" className="text-center px-2 py-3">
                Dhuhr
              </th>
              <th scope="col" className="text-center px-2 py-3">
                Dhuhr Jama'at
              </th>
              <th scope="col" className="text-center px-2 py-3">
                Asar
              </th>
              <th scope="col" className="text-center px-2 py-3">
                Asar Jama'at
              </th>
              <th scope="col" className="text-center px-2 py-3">
                Maghrib
              </th>
              <th scope="col" className="text-center px-2 py-3">
                Maghrib Jama'at
              </th>
              <th scope="col" className="text-center px-2 py-3">
                Isha
              </th>
              <th scope="col" className="text-center px-2 py-3">
                Isha Jama'at
              </th>
            </tr>
          </thead>
          <tbody>
            {dataArray &&
              dataArray.map((element, index) => (
                <TableRow
                  row={element}
                  index={index}
                  handleDataUpdate={handleDataUpdate}
                />
              ))}
          </tbody>
        </table>
        {temporary && temporary[monthName] && (
          <div className="flex justify-center">
            <button
              onClick={handleButton}
              type="button"
              class="text-center text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center m-2"
            >
              Save
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default TimeTable;
