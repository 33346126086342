import React, { useState } from "react";
import Logo from "../../assets/logo/Default_Design_a_elegant_and_modern_logo_for_Balgreen_Mosque_f_2_a1175021-bc21-49af-b5fc-3c03fb4df74a_0.png";
import { auth } from "../../firebaseAdmin";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useDispatch } from "react-redux";
import { triggerSnackbar } from "../../redux/reducers/snackbarSlicer";
import { showOrHideLoader } from "../../redux/reducers/loaderSlicer";
import { useNavigate } from "react-router-dom";

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleSubmit = (event) => {
    dispatch(showOrHideLoader());
    // console.log("handleSubmit");
    event.preventDefault();
    // console.log("Data : ", email, password);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        // console.log("Success : ", userCredential);
        // const user = userCredential.user;
        dispatch(
          triggerSnackbar({
            show: true,
            message: "Login Successful",
            type: "success",
          })
        );
        navigate("/admin");

        // ...
      })
      .catch((error) => {
        // console.error("Sign In Error : ", error);
        const errorCode = error.cod;
        const errorMessage = error.message;
        // console.log(errorCode, errorMessage);
        dispatch(
          triggerSnackbar({
            show: true,
            message: "Invalid Email/Password",
            type: "error",
          })
        );
      })
      .finally(() => {
        dispatch(showOrHideLoader());
      });
  };
  return (
    <div className="flex flex-col justify-center items-center h-screen w-full">
      <img src={Logo} width={300} style={{ aspectRatio: 1 }}></img>
      <div className="p-4 border-slate-500 border-2 rounded-lg">
        <form onSubmit={handleSubmit} className="w-[400px]">
          <div className="mb-5">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onInput={(e) => {
                setEmail(e.target.value);
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="name@flowbite.com"
              required
            />
          </div>
          <div className="mb-5">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onInput={(e) => {
                setPassword(e.target.value);
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>

          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default SignIn;
