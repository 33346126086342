import React from "react";
import backgroundImage from "../../assets/images/vecteezy_asanoha-japanese-traditional-seamless-pattern-with-modern_7169479.svg";
import aboutImage from "../../assets/images/about-img.png";
function AboutUs() {
  return (
    <div
      id="about"
      className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center justify-around py-8 px-4 lg:p-16 w-full h-full"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <img src={aboutImage} alt="background-image" />
      <div className="p-4">
        <h1 className="mb-4 text-[#DB9E2F] text-2xl lg:text-4xl font-semibold">
          About Us
        </h1>
        <div className="uppercase mb-4 uppercase text-2xl md:text-3xl lg:text-4xl font-bold">
          <h1 className="uppercase uppercase font-bold">
            Welcome <span className="text-[#DB9E2F]">to the</span>
          </h1>
          <h1 className="uppercase uppercase font-bold">
            ISLAM
            <span className="text-[#DB9E2F]"> HOUSE</span>
          </h1>
        </div>
        <h1>
          Islam appears to me like a perfect work of architecture. All its parts
          are harmoniously conceived to complement and support each other;
          nothing is superfluous and nothing lacking; and the result is a
          structure of absolute balance and solid composure.
        </h1>
      </div>
    </div>
  );
}

export default AboutUs;
