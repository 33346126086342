import React, { useState, useEffect } from "react";
import backgroundImage from "../../assets/images/content-abouts.jpg";
import divider from "../../assets/images/dividers-1.png";
import { database } from "../../firebaseAdmin";
import { ref as rldbRef, onValue, set } from "firebase/database";
import { useDispatch } from "react-redux";
import { triggerSnackbar } from "../../redux/reducers/snackbarSlicer";
import colorPalette from "../../constants/colors";
import {
  Facebook,
  Instagram,
  Mail,
  MapPin,
  Phone,
  Twitter,
  Youtube,
} from "lucide-react";

function GetInTouch() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [subscribers, setSubscribers] = useState([]);

  const writeData = (email) => {
    let subs = [...subscribers, email];
    subs = Array.from(new Set(subs));
    set(rldbRef(database, "subscribers"), subs)
      .then(() => {
        // console.log("Data written successfully!");
        setEmail("");
        dispatch(
          triggerSnackbar({
            show: true,
            message: "Thank you! for subscribing",
            type: "success",
          })
        );
      })
      .catch((error) => {
        console.error("Error writing data: ", error);
      });
  };
  const handleSubscribe = () => {
    // console.log("handleSubscribe", email);
    if (email.length) {
      writeData(email);
    }
  };

  useEffect(() => {
    // dispatch(showOrHideLoader());
    const starCountRef = rldbRef(database, "subscribers");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data !== undefined) {
        setSubscribers(data);
      }
    });
    // eslint-disable-next-line
  }, []);
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "round",
        backgroundSize: "contain",
      }}
    >
      <div className="bg-gradient-to-b from-gray-800/80 via-gray-900/90 to-black py-8">
        <h1 className="mb-4 text-[#DB9E2F] text-xl font-semibold text-center">
          Get In Touch
        </h1>
        <h1 className="mb-4 text-3xl font-bold text-center text-white">
          Newsletter
        </h1>
        <div className="flex flex-col items-center justify-center w-full">
          <img src={divider} alt="divider" />
          <div className="py-4">
            <input
              value={email}
              onInput={(e) => {
                setEmail(e.target.value);
              }}
              className="rounded-md"
              type="email"
              name="email"
              placeholder="Enter your email"
            />
            <button
              className="bg-[#DB9E2F] p-2 ml-2 rounded-md"
              onClick={handleSubscribe}
            >
              Subscribe
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 justify-around py-4 md:py-8 px-4 w-full h-full">
          <div className="text-center">
            <h1 className="uppercase text-white text-xl md:text-2xl font-bold">
              Quick Menu
            </h1>
            <ul className="text-[#817B70] font-semibold text-lg mt-2">
              <a href="/">
                <li>Home</li>
              </a>
              <a href="#about">
                <li>About</li>
              </a>
              <a href="#service">
                <li>Service</li>
              </a>
              <a href="#contact">
                <li>Contact</li>
              </a>
            </ul>
          </div>
          <div className="text-center">
            <h1 className="uppercase text-white text-xl md:text-2xl font-bold">
              Contact
            </h1>
            <ul className="text-[#817B70] font-semibold text-sm md:text-lg mt-2">
              <li className="flex items-center justify-center mt-2">
                <MapPin />
                <span className="ml-2">
                  199 Balgreen Rd, Edinburgh EH11 2RZ
                </span>
              </li>
              <li className="flex items-center justify-center mt-2">
                <Mail />
                <span className="ml-2">info@example.com</span>
              </li>
              <li className="flex items-center justify-center mt-2">
                <Phone />
                <span className="ml-2">021 3456 789</span>
              </li>
            </ul>
          </div>
          <div className="text-center">
            <h1 className="uppercase text-white text-xl md:text-2xl font-bold">
              Follow us
            </h1>
            <ul className="text-[#817B70] font-semibold text-lg mt-2 flex items-center justify-evenly">
              <Facebook />
              <Instagram />
              <Twitter />
              <Youtube />
            </ul>
          </div>
        </div>
      </div>
      <div
        className="w-[-webkit-fill-available] flex py-4 text-center justify-evenly text-xs flex-wrap gap-2"
        style={{
          ...colorPalette.footer,
        }}
      >
        <p className="text-gray-400">
          COPYRIGHT &copy; 2024 ALL RIGHTS RESERVED BY{" "}
          {process.env.REACT_APP_NAME.toUpperCase()}.
        </p>
        <p className="text-gray-400">
          DESIGNED & DEVELOPED BY{" "}
          <a
            href="https://samsinfosoft.com/"
            target="_blank"
            className="text-bold hover:underline target:blank"
            rel="noreferrer"
            style={{ ...colorPalette.footerQuickLinks }}
          >
            SAMS INFOSOFT PVT. LTD.
          </a>
        </p>
      </div>
    </div>
  );
}

export default GetInTouch;
