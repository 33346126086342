import React from "react";
import backgroundImage from "../../assets/images/side-bg.jpg";
import image from "../../assets/images/Untitled-design.png";
function Support() {
  return (
    <div
      className="-mt-2"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "round",
        backgroundSize: "contain",
      }}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 items-center justify-around py-8 px-4 md:px-16 w-full h-full bg-white bg-opacity-50">
        <div className="px-2">
          <h1 className="mb-4 text-[#DB9E2F] text-xl md:text-2xl font-semibold">
            Support Your Masjid Today
          </h1>
          <h1>
            Did you know that in just one month alone over 60 talks, reminders,
            courses and khutbahs will be delivered in various Masaajid and
            universities across the UK including a week-long university lecture
            tour, an intensive weekend course and many more events, some of
            which will take place at Green Lane Masjid.
          </h1>
          <ul className="ml-8 mt-4 list-disc">
            {/* <ul className="list-disc"> */}
            <li>
              <h1>In 2022 alone we have close to 1000 talks planned.</h1>
            </li>
            <li>
              <h1>
                If you would like to be part of this amazing work being done and
                have a share in the reward please donate.
              </h1>
            </li>
            <li>
              <h1>
                Remember every person that benefits or learns something new at
                these events, you will also receive a portion of the reward,
                don’t miss out!
              </h1>
            </li>
            <li>
              <h1>
                If you donate you can gain a share in the reward for every act
                of worship and service the masjid provides!
              </h1>
            </li>
          </ul>
          {/* </div> */}
          <h1 className="mt-4 font-bold italic">
            “The most beloved deed to Allah is the most regular and constant
            even if it were little.”{" "}
            <span className="font-normal">[Bukhari]</span>
          </h1>
        </div>
        <div className="p-4 rounded-md bg-[#FAF0E0]">
          <img src={image} alt="support-image" />
        </div>
      </div>
    </div>
  );
}

export default Support;
