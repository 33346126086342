import React from "react";
import Carousel from "./Carousel";
import TimeTable from "./TimeTable";
function CarouselAndTable() {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-[70%]">
        {/* <!-- 70% column content --> */}
        <Carousel />
      </div>
      <TimeTable />
    </div>
  );
}

export default CarouselAndTable;
