import React, { useEffect, useState } from "react";
// import APIHandler from '../../helpers/axiosApiHelper';
import MainComponent from "./Admin/MainComponent";
import LeftNavbar from "./navbars/LeftNavbar";
import { Helmet } from "react-helmet-async";
import { auth } from "../firebaseAdmin";
import { useNavigate } from "react-router-dom";
export default function Admin() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        // Get the token if needed
        user.getIdToken().then((token) => {
          // console.log("User token:", token);
        });
      } else {
        // console.log("Not logged in");
        setUser(null);
        navigate("/auth");
      }
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, []);
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} - Admin</title>
      </Helmet>

      <div className="flex flex-wrap sm:flex-nowrap">
        <LeftNavbar />
        <MainComponent />
      </div>
    </>
  );
}
