import React, { useEffect, useState } from "react";
import PageHeading from "../PageHeading";
import { ref as storageRef, deleteObject } from "firebase/storage";
import { storage, database } from "../../../firebaseAdmin";
import { ref as rldbRef, set, onValue } from "firebase/database";
import { useDispatch } from "react-redux";
import { showOrHideLoader } from "../../../redux/reducers/loaderSlicer";
import { triggerSnackbar } from "../../../redux/reducers/snackbarSlicer";

function AllPosters() {
  const dispatch = useDispatch();
  let [posters, setPosters] = useState([]);
  useEffect(() => {
    dispatch(showOrHideLoader());
    const starCountRef = rldbRef(database, "posters");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        setPosters(data);
      }
    });
    dispatch(showOrHideLoader());
    // eslint-disable-next-line
  }, []);

  const card = (item) => {
    const handleDeleteFile = (token) => {
      dispatch(showOrHideLoader());
      // Create a reference to the file to delete
      const desertRef = storageRef(storage, `posters/${item.token}`);

      // Delete the file
      deleteObject(desertRef)
        .then(() => {
          // console.log("File deleted successfully");
          let newPostersList = [];
          posters.forEach((element) => {
            if (element.token !== token) {
              newPostersList.push(element);
            }
          });
          set(rldbRef(database, "posters"), newPostersList)
            .then(() => {
              // console.log("Data written successfully!");
              setPosters(newPostersList);
              dispatch(
                triggerSnackbar({
                  show: true,
                  message: "Poster Deleted Successfully",
                  type: "success",
                })
              );
            })
            .catch((error) => {
              // dispatch(showOrHideLoader());
              console.error("Error writing data: ", error);
            });
        })
        .catch((error) => {
          console.log("Uh-oh, an error occurred!", error);
        })
        .finally(() => {
          dispatch(showOrHideLoader());
        });
    };
    return (
      <div
        key={item.token}
        className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
      >
        <div>
          <img
            className="w-full object-contain aspect-[16/12]"
            src={item.fileUrl}
            alt={item.fileName}
          />
        </div>
        <div className="p-5">
          {/* <a href="#"> */}
          <h5
            className="text-2xl md:text-sm lg:text-xl xl:text-2xl font-semibold mb-2"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item.fileName}
          </h5>
          {/* </a> */}
          <button
            onClick={() => {
              handleDeleteFile(item.token);
            }}
            type="button"
            className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
          >
            Delete
          </button>
        </div>
      </div>
    );
  };
  return (
    <>
      <PageHeading pageName={"All Posters"} />
      <div
        className="grid grid-cols-2 md:grid-cols-3 gap-4 py-8"
        key={"all-poster"}
      >
        {posters.length ? (
          posters.map((item, index) => {
            return card(item);
          })
        ) : (
          <h1>No Posters Added</h1>
        )}
      </div>
    </>
  );
}

export default AllPosters;
