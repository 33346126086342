import React from "react";
import PageHeading from "../PageHeading";
import PrayerMonthTabs from "./PrayerMonthTabs";

function PrayerTimeMain() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <PageHeading pageName={`Prayer Time Table - ${currentYear}`} />
      <PrayerMonthTabs currentYear={currentYear} />
    </>
  );
}

export default PrayerTimeMain;
