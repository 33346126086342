import React, { useEffect, useState } from "react";
import backgroundImage from "../../assets/images/aboutUsBackgroundImage.jpg";
import { monthNameAbbrToFull } from "../../constants/MonthName";
import Table from "./PrayerTimeTable/Table";
function TimeTable() {
  let currentDate = new Date().toDateString();
  currentDate = currentDate.split(" ");
  const year = currentDate[3];
  let month = currentDate[1];
  let monthFullName = monthNameAbbrToFull[month];
  const date = currentDate[2];
  const createTimeString = () => {
    let time = new Date().toLocaleTimeString().split(":");
    let hour = parseInt(time[0]);
    let minutes = parseInt(time[1]);
    let seconds = parseInt(time[2]);
    let format = "am";
    if (hour >= 12) {
      hour = hour % 12;
      format = "pm";
    }
    return `${hour >= 10 ? "" : "0"}${hour}:${minutes >= 10 ? "" : "0"}${minutes}:${seconds >= 10 ? "" : "0"}${seconds} ${format}`;
  };
  const [clock, setClock] = useState(createTimeString());

  useEffect(() => {
    const interval = setInterval(() => setClock(createTimeString()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div
      className="w-full md:w-[30%]"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        objectFit: "contain",
        backgroundRepeat: "round",
      }}
    >
      <div className="py-4">
        <h1 className="font-bold text-center text-xl">Today's Prayer Time</h1>
        <div className="flex justify-evenly">
          <h1 className="text-center font-semibold text-lg uppercase">
            {monthFullName} {date}, {year}
          </h1>
          <h1 className="text-center font-semibold text-lg uppercase">
            {clock}
          </h1>
        </div>
      </div>
      <Table year={year} month={month} date={date} />
      <div className="py-2 w-full flex flex-col text-center">
        <h1 className="text-xl font-bold">Jumu'ah Khutbah</h1>
        <p>
          Will be delivered at{" "}
          <span className="text-blue-700 font-bold">01:30 PM</span>
        </p>
      </div>
    </div>
  );
}

export default TimeTable;
