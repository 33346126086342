import React from "react";
import Logo from "../../assets/logo/Default_Design_a_elegant_and_modern_logo_for_Balgreen_Mosque_f_2_a1175021-bc21-49af-b5fc-3c03fb4df74a_0.png";
function Navbar() {
  return (
    <>
      <nav className="bg-white border-gray-200 dark:bg-gray-900 transition-all duration-1000 ease-in-out">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a
            href="https://flowbite.com/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src={Logo} className="w-12" alt="Flowbite Logo" />
            <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
              Balgreen Mosque
            </span>
          </a>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="hidden bg-orange-400 items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className="hidden w-full md:block md:w-auto transition-all duration-300 ease-in-out"
            id="navbar-default"
          >
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <a
                  href="/"
                  className="block py-2 px-3 text-black rounded hover:text-[#DB9E2F]"
                  aria-current="page"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#about"
                  className="block py-2 px-3 text-black rounded hover:text-[#DB9E2F]"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#service"
                  className="block py-2 px-3 text-black rounded hover:text-[#DB9E2F]"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  className="block py-2 px-3 text-black rounded hover:text-[#DB9E2F]"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
